// material-ui
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
	Box,
	Button,
	Stack,
	Popper,
	List,
	ListItemButton,
	Paper,
	ListItemText,
	Typography,
	Divider,
	ClickAwayListener,
	ButtonBase,
} from "@mui/material";
import LogoSection from "layout/MainLayout/LogoSection";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileSection from "layout/MainLayout/Header/ProfileSection";
import { IconBook2, IconMenu2 } from "@tabler/icons-react";
import Transitions from "ui-component/extended/Transitions";
import MainCard from "ui-component/cards/MainCard";
import { useMediaQuery } from "@mui/material";
import { IconExternalLink } from "@tabler/icons-react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
	const theme = useTheme();
	const { pathname } = useLocation();
	const account = useSelector((state) => state.account);
	const [open, setOpen] = useState(null);
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const handleOpenMenu = (event) => {
		setOpen(open ? null : event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	return (
		<>
			<Box
				sx={{
					width: 228,
					display: "flex",
					[theme.breakpoints.down("md")]: {
						width: "auto",
					},
				}}
			>
				<Box component="span" sx={{ flexGrow: 1, maxWidth: "60%" }}>
					<LogoSection />
				</Box>
			</Box>

			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ flexGrow: 1 }} />
			<Stack
				spacing={2}
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				{isMobile ? (
					<>
						{/* <ThemeButton /> */}
						<ButtonBase
							disableRipple
							onClick={handleOpenMenu}
							sx={{
								borderRadius: "12px",
								overflow: "hidden",
								...theme.typography.commonAvatar,
								...theme.typography.mediumAvatar,
								...theme.typography.menuButton,
								transition: "all .2s ease-in-out",
								"&:hover": {
									background: theme.palette.secondary.dark,
									color: theme.palette.secondary.light,
								},
							}}
						>
							<IconMenu2 stroke={1.5} size="1.3rem" />
						</ButtonBase>
					</>
				) : (
					<>
						<Button
							component={Link}
							variant="text"
							to="/"
							color={pathname === "/" ? "primary" : "inherit"}
						>
							صفحه اصلی
						</Button>
						<Button
							component={Link}
							variant="text"
							to="/about"
							color={
								pathname === "/about" ? "primary" : "inherit"
							}
						>
							درباره ما
						</Button>
						<Button
							component={"a"}
							variant="text"
							href={process.env.REACT_APP_PUBLIC_URL + "/blog"}
							target="_blank"
							color={"inherit"}
						>
							بلاگ
						</Button>
						<Button
							component={"a"}
							variant="outlined"
							href={process.env.REACT_APP_DOCS_URL}
							target="_blank"
							color={"warning"}
							startIcon={<IconBook2 />}
						>
							مستندات
						</Button>
						<Button
							component={"a"}
							variant="contained"
							href={process.env.REACT_APP_CHAT_URL}
							target="_blank"
							color={"secondary"}
							startIcon={<IconExternalLink />}
						>
							صفحه چت
						</Button>
						{/* <ThemeButton /> */}
						{account.user ? (
							<>
								<Button
									component={Link}
									variant="contained"
									to="/panel"
									color="primary"
								>
									پنل
								</Button>
								<ProfileSection />
							</>
						) : (
							<Button
								component={Link}
								variant="contained"
								to="/login"
								color="primary"
							>
								ورود
							</Button>
						)}
					</>
				)}
			</Stack>

			<Popper
				open={!!open}
				anchorEl={open}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, 14],
							},
						},
					],
				}}
				style={{ width: "100vw" }}
			>
				{({ TransitionProps }) => (
					<Transitions in={open} {...TransitionProps}>
						<ClickAwayListener onClickAway={handleCloseMenu}>
							<Paper style={{ width: "100%" }}>
								<MainCard
									border={false}
									elevation={16}
									content={false}
									boxShadow
									shadow={theme.shadows[16]}
								>
									<List
										component="nav"
										sx={{
											width: "100%",
											maxWidth: "100%",
											minWidth: "100%",
											backgroundColor:
												theme.palette.background.paper,

											"& .MuiListItemButton-root": {
												mt: 0.5,
											},
										}}
										onClick={handleCloseMenu}
									>
										<ListItemButton
											component={Link}
											variant="text"
											to="/"
										>
											<ListItemText
												primary={
													<Typography variant="body2">
														صفحه اصلی
													</Typography>
												}
											/>
										</ListItemButton>

										<ListItemButton
											component={Link}
											variant="text"
											to="/about"
										>
											<ListItemText
												primary={
													<Typography variant="body2">
														درباره ما
													</Typography>
												}
											/>
										</ListItemButton>
										<ListItemButton
											component={"a"}
											variant="text"
											href={
												process.env
													.REACT_APP_PUBLIC_URL +
												"/blog"
											}
											target="_blank"
										>
											<ListItemText
												primary={
													<Typography variant="body2">
														بلاگ
													</Typography>
												}
											/>
										</ListItemButton>
										<Divider />
										<ListItemButton
											component={"a"}
											variant="text"
											color="warning"
											href={
												process.env.REACT_APP_DOCS_URL
											}
											target="_blank"
										>
											<ListItemText
												primary={
													<Typography variant="body2">
														مستندات
													</Typography>
												}
											/>
										</ListItemButton>
										<ListItemButton
											component={"a"}
											variant="text"
											color="secondary"
											href={
												process.env.REACT_APP_CHAT_URL
											}
											target="_blank"
										>
											<ListItemText
												primary={
													<Typography variant="body2">
														صفحه چت
													</Typography>
												}
											/>
										</ListItemButton>
										<Divider />
										{account.user ? (
											<ListItemButton
												component={Link}
												variant="contained"
												to="/panel"
												color="primary"
											>
												پنل
											</ListItemButton>
										) : (
											<ListItemButton
												component={Link}
												variant="contained"
												to="/login"
												color="primary"
											>
												ورود
											</ListItemButton>
										)}
									</List>
								</MainCard>
							</Paper>
						</ClickAwayListener>
					</Transitions>
				)}
			</Popper>
		</>
	);
};

export default Header;
