import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SET_THEME } from "store/actions";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

// auth
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import StatusProvider from "contexts/StatusContext";
import UserProvider from "contexts/UserContext";
import { SnackbarProvider } from "notistack";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { API } from "utils/api";

// ==============================|| APP ||============================== //

const App = () => {
	document.dir = "rtl";
	const cacheRtl = createCache({
		key: "muirtl",
		stylisPlugins: [prefixer, rtlPlugin],
	});
	const dispatch = useDispatch();
	const customization = useSelector((state) => state.customization);

	useEffect(() => {
		const storedTheme = localStorage.getItem("theme");
		if (storedTheme) {
			dispatch({ type: SET_THEME, theme: storedTheme });
		}
	}, [dispatch]);
	useEffect(() => {
		
	}, []);

	return (
		<CacheProvider value={cacheRtl}>
			<ThemeProvider theme={themes(customization)}>
				<StyledEngineProvider injectFirst>
					<CssBaseline />
					<NavigationScroll>
						<SnackbarProvider
							autoHideDuration={5000}
							maxSnack={3}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<UserProvider>
								<StatusProvider>
									<Routes />
								</StatusProvider>
							</UserProvider>
						</SnackbarProvider>
					</NavigationScroll>
				</StyledEngineProvider>
			</ThemeProvider>
		</CacheProvider>
	);
};

export default App;
